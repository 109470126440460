import Main from "./components/Main/Main";


function App() {
  return (
<Main/>
  );
}

export default App;
