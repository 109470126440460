import "../Main/Main.scss";


export default function Main() {

    return (
        <div className="c-main">
            <h1 className="c-main__h1">Web in progress...</h1>
            <h4 className="c-main__h4">Coming soon!</h4>
            <h3 className="c-main__h3">Macarena Paz© 2023</h3>
        </div>
    )
}